import Smooth from "smooth-scrolling";

class Parallax extends Smooth {
    constructor(opt) {
        super(opt);
        this.createExtraBound();
        this.documentHeight = 0;
        this.destroyed = false;
        this.resizing = false;
        this.cache = null;
        this.dom.divs = Array.prototype.slice.call(opt.divs, 0);
        // custom settings
        this.parentOffset =
            (this.options.parent && this.options.parent.offsetTop) || 0;
        this.scrollContainerHeight = 0;
        this.containerSample = {
            el: document.querySelector(".container"),
            sideMargin: 0,
        };
        this.checkDocumentHeightAndResizeIfNecessary();
    }

    destroy() {
        this.destroyed = true;
        super.destroy();
    }

    createExtraBound() {
        ["getCache", "inViewport"].forEach((fn) => {
            this[fn] = this[fn].bind(this);
        });
    }

    checkDocumentHeightAndResizeIfNecessary() {
        if (this.destroyed) {
            return;
        }

        setTimeout(
            this.doCheckDocumentHeightAndResizeIfNecessary.bind(this),
            250
        );
    }

    doCheckDocumentHeightAndResizeIfNecessary() {
        const calculatedHeight = Math.floor(document.body.offsetHeight);

        if (calculatedHeight !== this.documentHeight) {
            this.documentHeight = calculatedHeight;

            try {
                this.resize();
            } catch (err) {}
        }

        window.requestAnimationFrame(
            this.checkDocumentHeightAndResizeIfNecessary.bind(this)
        );
    }

    resize() {
        this.resizing = true;
        this.getCache();
        super.resize();
        this.resizing = false;
    }

    getCache() {
        this.cache = [];

        // get offset of container that has slider
        this.parentOffset =
            (this.options.parent && this.options.parent.offsetTop) || 0;
        const parentBoundings = this.options.parent.getBoundingClientRect();
        // get margin of the grid-container, used to offset first & last item
        this.containerSample.sideMargin =
            this.containerSample.el.offsetLeft +
            +window
                .getComputedStyle(this.containerSample.el, null)
                .getPropertyValue("padding-left")
                .replace("px", "");
        // add offset for first / last item
        if (this.dom.divs.length) {
            this.dom.divs[0].style.marginLeft = `${this.containerSample.sideMargin}px`;
            this.dom.divs[
                this.dom.divs.length - 1
            ].style.marginRight = `${this.containerSample.sideMargin}px`;
        }

        this.dom.divs.forEach((el) => {
            const scrollX = this.vars.target;
            const bounding = el.getBoundingClientRect();
            const imgWrap = el.querySelector(".js-img-wrap");
            
            if(!imgWrap){
                return;
            }

            const imgWrapBounding = imgWrap.getBoundingClientRect();
            const imgEl = el.querySelector(".js-img");

            const bounds = {
                el: el,
                img: {
                    // img for parallax scroll effect
                    el: imgEl,
                    wrap: imgWrap,
                    wrapWidth: imgWrapBounding.width,
                },
                state: true,
                width: bounding.width,
                left: bounding.left + scrollX,
                right: bounding.right + scrollX,
                center: bounding.width / 2,
            };

            this.cache.push(bounds);
        });

        // calculate & set scroll-container height so that scrolling ends with last element in view
        const unit =
            this.dom.divs.length && this.dom.divs[0].getBoundingClientRect();
        this.scrollContainerHeight =
            this.vars.height +
            Math.max(
                0,
                unit.width * this.dom.divs.length -
                    parentBoundings.width +
                    this.containerSample.sideMargin * 2
            );
        this.options.parent.style.height = `${this.scrollContainerHeight}px`;
    }

    run() {
        if (this.isRAFCanceled) return;

        this.vars.current +=
            (this.vars.target - this.parentOffset - this.vars.current) *
            this.vars.ease;
        this.vars.current < 0.1 && (this.vars.current = 0);
        this.requestAnimationFrame();

        // get relative scroll position to the container element
        const scrollPosition = Math.max(
            0,
            Math.min(
                this.vars.current.toFixed(2),
                this.scrollContainerHeight - this.vars.height
            )
        );
        // render image transformations (parallax) for each item in view & transform slider
        this.dom.divs.forEach(this.inViewport);
        this.dom.section.style[
            this.prefix
        ] = `translate3d(${-scrollPosition}px,0,0)`;

        if (this.callback && this.vars.current !== this.vars.last) {
            this.callback(this.vars.current);
        }

        this.vars.last = this.vars.current;
    }

    inViewport(el, index) {
        if (!this.cache || this.resizing) return;

        const cache = this.cache[index];

        if(!cache){
            return;
        }

        const current = this.vars.current;
        const left = Math.round(cache.left - current);
        const right = Math.round(cache.right - current);
        const inview = right > 0 && left < this.vars.width;
        // returns the max translate of the image, max 50% of the image width as scale is set to 1.5 (ergo, max 50% allowed)
        const imgOffset = Math.min(
            cache.img.wrapWidth / 2,
            Math.max(
                0,
                ((this.vars.height - current) / this.vars.height) *
                    (cache.img.wrapWidth / 2)
            )
        );

        if (inview) {
            // last part: stop transforming image if scrolling reached maximum
            if (
                !el.state &&
                cache.img.el &&
                this.vars.current.toFixed(2) <
                    this.scrollContainerHeight - this.vars.height
            ) {
                // cache.img.el.style[
                //     this.prefix
                // ] = `translate3d(${-imgOffset}px, 0, 0) scale(1.5)`;
            }
        } else {
            el.state = false;
        }
    }
}

window.ParallaxSmoothScroller = Parallax;
